export default class Menu {

    constructor( element ) {
        this.element = element;
        this.menu = document.querySelector('[data-menu]');
        this.header = document.querySelector('[data-header]');

        this.addEventListeners();
        this.documentElement = document.documentElement;

    }

    addEventListeners() {
        this.element.addEventListener('click', (event) => {
            this.toggleMenu();
        })
    }

    toggleMenu() {
        this.element.classList.toggle('open');
        this.menu.classList.toggle('visible');
        this.header.classList.toggle('menu-open');
        this.documentElement.classList.toggle( 'no-scroll' );

    }


}