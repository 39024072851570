import Menu from './components/menu';
import InView from './components/in-view';

const menuElement = document.querySelector('[data-hamburger]');

if ( menuElement ) {
    const menu = new Menu (menuElement);
}

const inView = new InView( '[data-in-view]' );
